export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDm1sydhZLQoSwR_MTrwigahZLgwJt4Oik",
    authDomain: "traveling-women-talk.firebaseapp.com",
    databaseURL: "https://traveling-women-talk.firebaseio.com",
    projectId: "traveling-women-talk",
    storageBucket: "traveling-women-talk.appspot.com",
    messagingSenderId: "1023540256424",
    appId: "1:1023540256424:web:311b775f8fce81f31a6c9d"
  }
};
